<template>
  <router-view />
</template>

<style lang="scss"> 
 @import 'styles/style'; 
 @import 'https://unpkg.com/placeholder-loading/dist/css/placeholder-loading.min.css';

 .ph-item { 
  margin-bottom: 0px;
  background-color: transparent;  
  border:none;
  padding: 10px;
 }

 .ph-item>* {
   margin-bottom: 0px;  
   padding-left: 0px;  
   padding-right: 0px;   
}
</style>
 
