<template>
  <CFooter>
    <div>
      <a href="https://coreui.io" target="_blank">CoreUI</a>
      <span class="ms-1"
        >&copy; {{ new Date().getFullYear() }} creativeLabs. 1.0</span
      >
    </div>
    <div class="ms-auto">
      <span class="me-1" target="_blank">Powered by</span>
      <a href="https://coreui.io/vue">CoreUI for Vue</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
